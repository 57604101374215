/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  Select,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import 'assets/css/ScrollBar.css';
import ExpandableCell from 'views/dashboard/accountshq/components/ExpandableCell';
import AccountsHQDropdowns from '../variables/dropdowns/scratch-pad.json';
import AccountsHQData from 'views/dashboard/accountshq/variables/AccountsHQData.json';

const columnHelper = createColumnHelper();

export default function WorkbookTable(props) {
  const [sorting, setSorting] = React.useState([]);
  const { searchInput, setSearchInput } = props;

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [expandedCellId, setExpandedCellId] = React.useState(null);

  const [curAccountsHQDropdowns, setCurAccountsHQDropdowns] = React.useState(
    AccountsHQDropdowns.slice(5, 10),
  );

  const handleDropdownChange = (e, index) => {
    const selectedValue = e.target.value;
    const selectedItem = AccountsHQDropdowns.find(
      (item) => item.name === selectedValue,
    );

    if (selectedItem) {
      setCurAccountsHQDropdowns(prev => {
        const updated = [...prev];
        updated[index] = selectedItem;
        return updated;
      });
    }
  };

  let defaultData = AccountsHQData;

  const dropdownColumns = curAccountsHQDropdowns.map((value, index) =>
    columnHelper.accessor(value.id, {
      id: value.id,
      header: () => (
        <Select
          key={index}
          fontSize="12px"
          value={value.name}
          onChange={(e) => handleDropdownChange(e, index)}
        >
          {AccountsHQDropdowns.filter(
            (item) =>
              !Object.values(curAccountsHQDropdowns)
                .map((item) => item.name)
                .includes(item.name) || item.name === value.name,
          ).map((item) => {
            return <option value={item.name}>{item.name}</option>;
          })}
        </Select>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="120px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
      enableSorting: false,
    }),
  );

  const columns = [
    columnHelper.accessor('tiktok_main', {
      id: 'tiktok_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TIKTOK MAIN
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
  ].concat(dropdownColumns);

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        justifyContent={{ xl: 'space-between' }}
        gap={{ base: '20px', xl: '20px' }}
        padding={{ base: '20px', xl: '20px' }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          MasterPals
        </Text>
        <Flex
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
          alignItems={{ xl: 'center' }}
          justifyContent={{ xl: 'end' }}
          gap={{ base: '20px', xl: '20px' }}
        >
          <Input
            size={{ sm: 'sm', md: 'md' }}
            placeholder="Search by TikTok Main.."
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              if (e.target.value.length > 0) {
                const filteredData = defaultData.filter((item) =>
                  item['tiktok_main'].includes(e.target.value),
                );
                setData(filteredData);
              } else {
                setData([...defaultData]);
              }
            }}
          ></Input>
        </Flex>
      </Flex>
      <Box>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id} height="50px">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" mt="5">
        <Button
          pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
          color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex - 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex - 1);
          }}
        >
          Previous
        </Button>
        <Text p="1">
          Page{' '}
          {table.getPageOptions().length > 0
            ? table.getState().pagination.pageIndex + 1
            : 0}{' '}
          of {table.getPageOptions().length}
        </Text>
        <Button
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex + 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex + 1);
          }}
          pointerEvents={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
          }
          color={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
          }
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
