/* eslint-disable */

import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Input
  } from '@chakra-ui/react';
  import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table';
  import Card from 'components/card/Card';
  import * as React from 'react';
  import 'assets/css/ScrollBar.css';
  import ExpandableCell from 'views/dashboard/accountshq/components/ExpandableCell';
  
  const columnHelper = createColumnHelper();
  
  export default function CastingOppTable(props) {
    const { tableData, curCastingOpp} = props;
    const [searchInput, setSearchInput] = React.useState("");
    const [sorting, setSorting] = React.useState([]);
   
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: 5,
    });
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [expandedCellId, setExpandedCellId] = React.useState(null);
  
    let defaultData = tableData.filter(item => item.casting_opp_name === curCastingOpp.name)[0].creators;
    const columns = [
      columnHelper.accessor('creator', {
        id: 'creator',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            CREATOR
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="100px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            NAME
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('marking', {
        id: 'marking',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            RATE
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('tiktok_main_link', {
        id: 'tiktok_main_link',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            TIKTOK MAIN
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('tiktok_followers', {
        id: 'tiktok_followers',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            FOLLOWERS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('tiktok_avg_views', {
        id: 'tiktok_avg_views',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            AVG. VIEWS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('ig_main', {
        id: 'ig_main',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            IG MAIN
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('ig_followers', {
        id: 'ig_followers',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            IG MAIN FOLLOWERS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('ig_avg_views', {
        id: 'ig_avg_views',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            IG MAIN AVG. VIEWS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="120px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('yt_main', {
        id: 'yt_main',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            YT MAIN
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="120px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('yt_followers', {
        id: 'yt_followers',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            YT FOLLOWERS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="50px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('yt_avg_views', {
        id: 'yt_avg_views',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            YT AVG. VIEWS
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="80px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('snapchat_link', {
        id: 'snapchat_link',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            SNAPCHAT LINK
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="80px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
      columnHelper.accessor('analytics_folder', {
        id: 'analytics_folder',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            ANALYTICS FOLDER
          </Text>
        ),
        cell: (info) => (
          <ExpandableCell
            value={info.getValue()}
            width="80px"
            textColor={textColor}
            cellId={info.cell.id}
            expandedCellId={expandedCellId}
            setExpandedCellId={setExpandedCellId}
          />
        ),
      }),
    ];
    const [data, setData] = React.useState(() => [...defaultData]);
    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        pagination,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });

    React.useEffect(() => {
        setData(tableData.filter(item => item.casting_opp_name === curCastingOpp.name)[0].creators)
    }, [curCastingOpp]);
  
    return (
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
          alignItems={{ xl: 'center' }}
          justifyContent={{ xl: 'space-between' }}
          gap={{ base: '20px', xl: '20px' }}
          padding={{ base: '20px', xl: '20px' }}
        >
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Creators
          </Text>
          <Flex
            flexDirection={{
              sm: 'column',
              md: 'row',
            }}
            alignItems={{ xl: 'center' }}
            justifyContent={{ xl: 'end' }}
            gap={{ base: '20px', xl: '20px' }}
          >
            <Input
              size={{ sm: 'sm', md: 'md' }}
              placeholder="Search by Creator.."
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                if (e.target.value.length > 0) {
                  const filteredData = defaultData.filter((item) =>
                    item['creator'].includes(e.target.value),
                  );
                  setData(filteredData);
                } else {
                  setData([...defaultData]);
                }
              }}
            ></Input>
          </Flex>
        </Flex>
        <Box className="scroll-wrapper">
          <Box className="custom-scrollbar">
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted()] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table
                  .getRowModel()
                  .rows.slice(0, 5)
                  .map((row) => {
                    return (
                      <Tr key={row.id} height="50px">
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Td
                              key={cell.id}
                              fontSize={{ sm: '14px' }}
                              minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                              borderColor="transparent"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center" mt="5">
          <Button
            pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
            color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
            onClick={() => {
              setPagination({
                pageIndex: table.getState().pagination.pageIndex - 1,
                pageSize: 5,
              });
              table.setPageIndex(table.getState().pagination.pageIndex - 1);
            }}
          >
            Previous
          </Button>
          <Text p="1">
            Page{' '}
            {table.getPageOptions().length > 0
              ? table.getState().pagination.pageIndex + 1
              : 0}{' '}
            of {table.getPageOptions().length}
          </Text>
          <Button
            onClick={() => {
              setPagination({
                pageIndex: table.getState().pagination.pageIndex + 1,
                pageSize: 5,
              });
              table.setPageIndex(table.getState().pagination.pageIndex + 1);
            }}
            pointerEvents={
              pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
            }
            color={
              pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
            }
          >
            Next
          </Button>
        </Box>
      </Card>
    );
  }
  