
function pieChartOptions(data) {
    return {
    labels: data.map(item => item.name),
    colors: data.map(item => item.color),
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    fill: {
        colors: data.map(item => item.color),
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };
}

export default pieChartOptions;