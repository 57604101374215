import { createIcon } from '@chakra-ui/icons';

export const AdobexdLogo = createIcon({
  displayName: 'AdobexdLogo',
  viewBox: '0 0 24 24',
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z"
        fill="#470137"
      />
      <path
        d="M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z"
        fill="#FF61F6"
      />
    </g>
  ),
});
export const AppleLogo = createIcon({
  displayName: 'AppleLogo',
  viewBox: '0 0 15 18',
  path: (
    <g width="15" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#Apple_logo_black 1__a)">
        <path
          d="M12.507 17.28c-.966.947-2.031.8-3.046.353-1.08-.456-2.067-.484-3.207 0-1.42.619-2.173.439-3.029-.353C-1.602 12.258-.89 4.608 4.597 4.32c1.33.072 2.263.743 3.046.8 1.166-.24 2.28-.926 3.528-.836 1.498.122 2.619.72 3.367 1.795-3.082 1.872-2.352 5.976.479 7.128-.566 1.503-1.293 2.988-2.512 4.086l.002-.013ZM7.537 4.266C7.392 2.034 9.183.198 11.242.018c.283 2.574-2.316 4.5-3.705 4.248Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="Apple_logo_black 1__a">
          <path fill="currentColor" d="M0 0h15v18H0z" />
        </clipPath>
      </defs>
    </g>
  ),
});
export const AndroidLogo = createIcon({
  displayName: 'AndroidLogo',
  viewBox: '0 0 16 18',
  path: (
    <g width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.9.051a.571.571 0 0 0-.263.066.518.518 0 0 0-.214.715l.595 1.029C3.584 2.71 2.634 4.16 2.634 5.815v.307a1.525 1.525 0 0 0-1.132-.494C.672 5.628 0 6.27 0 7.064v4.426c0 .793.673 1.437 1.502 1.437.452 0 .857-.192 1.132-.495v.62c0 .836.715 1.52 1.588 1.52h.404v1.969c0 .793.672 1.436 1.501 1.436.829 0 1.502-.643 1.502-1.436v-1.97h.697v1.97c0 .793.673 1.436 1.502 1.436.828 0 1.501-.643 1.501-1.436v-1.97h.403c.874 0 1.588-.683 1.588-1.519v-.62c.276.303.68.495 1.133.495.829 0 1.502-.644 1.502-1.437V7.064c0-.793-.673-1.436-1.502-1.436-.452 0-.857.191-1.133.494v-.307c0-1.655-.952-3.104-2.385-3.953l.596-1.03a.518.518 0 0 0-.214-.715.571.571 0 0 0-.748.205L9.945 1.4a5.906 5.906 0 0 0-1.968-.333c-.692 0-1.357.117-1.967.332L5.386.322a.556.556 0 0 0-.487-.27Z"
        fill="#fffcurrentColor"
      />
      <path
        d="M1.501 6.002c-.618 0-1.11.47-1.11 1.062v4.426c0 .592.492 1.063 1.11 1.063.618 0 1.11-.471 1.11-1.063V7.064c0-.591-.492-1.062-1.11-1.062Zm12.952 0c-.619 0-1.11.47-1.11 1.062v4.426c0 .592.491 1.063 1.11 1.063.618 0 1.11-.471 1.11-1.063V7.064c0-.591-.492-1.062-1.11-1.062Zm-8.327 5.05c-.618 0-1.11.471-1.11 1.063v4.426c0 .591.492 1.062 1.11 1.062.618 0 1.11-.47 1.11-1.062v-4.426c0-.592-.492-1.063-1.11-1.063Zm3.7 0c-.618 0-1.11.471-1.11 1.063v4.426c0 .591.492 1.062 1.11 1.062.619 0 1.111-.47 1.111-1.062v-4.426c0-.592-.492-1.063-1.11-1.063Z"
        fill="currentColor"
      />
      <path
        d="M4.9.426a.16.16 0 0 0-.074.02.141.141 0 0 0-.061.204l.782 1.352c-1.505.75-2.522 2.174-2.524 3.808h9.905c-.003-1.634-1.02-3.058-2.524-3.808L11.186.65c.044-.076.018-.163-.061-.205a.16.16 0 0 0-.075-.02.157.157 0 0 0-.14.079l-.792 1.367a5.483 5.483 0 0 0-2.143-.43c-.767 0-1.494.154-2.142.43L5.04.504A.157.157 0 0 0 4.9.426ZM3.024 6.184v6.868c0 .635.534 1.146 1.198 1.146h7.51c.663 0 1.197-.511 1.197-1.146V6.184H3.023Z"
        fill="currentColor"
      />
      <path
        d="M5.69 3.285a.407.407 0 0 0-.413.395c0 .216.187.395.414.395a.407.407 0 0 0 .413-.395.407.407 0 0 0-.413-.395Zm4.572 0a.407.407 0 0 0-.414.395c0 .216.188.395.414.395a.407.407 0 0 0 .413-.395.407.407 0 0 0-.413-.395Z"
        fill="currentColor"
      />
    </g>
  ),
});
export const WindowsLogo = createIcon({
  displayName: 'WindowsLogo',
  viewBox: '0 0 19 18',
  path: (
    <g width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m0 2.537 7.705-.994.004 7.04-7.702.042L0 2.537Zm7.701 6.858.006 7.047L.006 15.44V9.348l7.695.047Zm.935-7.982L18.852 0v8.494l-10.216.077V1.413ZM18.854 9.46l-.002 8.456L8.636 16.55l-.015-7.106 10.233.016Z"
        fill="currentColor"
      />
    </g>
  ),
});

export const AtlassianLogo = createIcon({
  displayName: 'AtlassianLogo',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        d="M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z"
        fill="#2684FF"
      />
      <path
        d="M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z"
        fill="#2684FF"
      />
    </g>
  ),
});

export const CartIcon = createIcon({
  displayName: 'CartIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z"
    />
  ),
});

export const ClockIcon = createIcon({
  displayName: 'ClockIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
});

export const CreditIcon = createIcon({
  displayName: 'CreditIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z"
    />
  ),
});

export const DashboardLogo = createIcon({
  displayName: 'DashboardLogo',
  viewBox: '0 0 1000 257',
  path: (
    <g width="998" height="257" viewBox="0 0 998 257" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z"
          fill="currentColor"
        />
        <path
          d="M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z"
          fill="currentColor"
        />
        <path
          d="M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z"
          fill="currentColor"
        />
        <path
          d="M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z"
          fill="currentColor"
        />
        <path
          d="M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z"
          fill="currentColor"
        />
        <path
          d="M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z"
          fill="currentColor"
        />
        <rect width="257" height="257" rx="128.5" fill="#4FD1C5" />
        <path
          d="M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="128.5"
          y1="0"
          x2="128.5"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7BCBD4" />
          <stop offset="1" stop-color="#29C6B7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="997.832" height="257" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export const DashboardLogoWhite = createIcon({
  displayName: 'DashboardLogo',
  viewBox: '0 0 163.5 42',
  path: (
    <g fill="none">
      <path
        d="M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z"
        fill="#fff"
      />
      <path
        d="M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z"
        fill="#3BCBBE"
      />
    </g>
  ),
});

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M18.809 10.344h-6.153a2.11 2.11 0 01-2.11-2.11V2.083a.176.176 0 00-.175-.176H6.328A2.812 2.812 0 003.516 4.72v14.063a2.812 2.812 0 002.812 2.812h9.844a2.812 2.812 0 002.812-2.813V10.52a.176.176 0 00-.175-.176z"
      />
      <path
        fill="currentColor"
        d="M18.423 8.789l-6.32-6.32a.088.088 0 00-.15.062v5.705a.703.703 0 00.703.703h5.705a.088.088 0 00.062-.15z"
      />
    </g>
  ),
});

export const HelpIcon = createIcon({
  displayName: 'HelpIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M11.494 4.951a.351.351 0 00-.486 0l-8.09 7.729a.352.352 0 00-.109.254v7.254a1.406 1.406 0 001.405 1.406h4.223a.703.703 0 00.704-.703v-5.976a.351.351 0 01.351-.352h3.516a.351.351 0 01.351.352v5.976a.703.703 0 00.704.703h4.22a1.407 1.407 0 001.407-1.406v-7.254a.35.35 0 00-.108-.254L11.494 4.95z"
      />
      <path
        fill="currentColor"
        d="M21.574 11.23l-3.287-3.144V3.314a.703.703 0 00-.703-.703h-2.11a.703.703 0 00-.703.703V4.72l-2.545-2.434c-.239-.24-.593-.378-.976-.378-.38 0-.734.138-.972.379L.93 11.23a.717.717 0 00-.058.983.703.703 0 001.018.046l9.119-8.713a.352.352 0 01.486 0l9.12 8.713a.703.703 0 00.992-.019c.27-.28.248-.74-.033-1.01z"
      />
    </g>
  ),
});

export const PersonIcon = createIcon({
  displayName: 'PersonIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M14.618 3.338c-.855-.924-2.05-1.432-3.368-1.432-1.325 0-2.524.505-3.375 1.423-.86.928-1.28 2.188-1.181 3.55.195 2.686 2.239 4.87 4.556 4.87s4.358-2.184 4.556-4.87c.1-1.349-.322-2.607-1.188-3.541zM18.984 21.592H3.515a1.363 1.363 0 01-1.063-.489 1.552 1.552 0 01-.316-1.279c.371-2.058 1.529-3.786 3.348-5 1.616-1.076 3.664-1.67 5.766-1.67s4.15.594 5.765 1.67c1.82 1.214 2.977 2.942 3.348 5 .085.471-.03.937-.315 1.279a1.362 1.362 0 01-1.064.49z"
    />
  ),
});

export const ProfileIcon = createIcon({
  displayName: 'ProfileIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path d="M0 0h24v24H0V0z" fill="transparent" />
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
      />
    </g>
  ),
});

export const RocketIcon = createIcon({
  displayName: 'RocketIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z"
      />
      <path
        fill="currentColor"
        d="M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z"
      />
    </g>
  ),
});

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
      />
    </g>
  ),
});

export const SlackLogo = createIcon({
  displayName: 'SlackLogo',
  viewBox: '0 0 24 24',
  path: (
    <g clipPath="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd">
      <path
        d="M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z"
        fill="#36C5F0"
      />
      <path
        d="M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z"
        fill="#2EB67D"
      />
      <path
        d="M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z"
        fill="#ECB22E"
      />
      <path
        d="M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z"
        fill="#E01E5A"
      />
    </g>
  ),
});

export const PaletteLogo = createIcon({
  displayName: 'PaletteLogo',
  viewBox: '0 0 908 431',
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", fillRule: "evenodd", clipRule: "evenodd"}}
    >
      <g>
        <path
          style={{opacity:0.999}}
          fill="#3bc2e0"
          d="M 86.5,75.5 C 137.5,75.5 188.5,75.5 239.5,75.5C 239.5,126.167 239.5,176.833 239.5,227.5C 188.5,227.5 137.5,227.5 86.5,227.5C 86.5,176.833 86.5,126.167 86.5,75.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.998}}
          fill="#3bc1e0"
          d="M 269.5,75.5 C 320.5,75.5 371.5,75.5 422.5,75.5C 422.5,126.167 422.5,176.833 422.5,227.5C 371.5,227.5 320.5,227.5 269.5,227.5C 269.5,176.833 269.5,126.167 269.5,75.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.996}}
          fill="#3cc2df"
          d="M 460.5,75.5 C 511.167,75.5 561.833,75.5 612.5,75.5C 612.5,126.167 612.5,176.833 612.5,227.5C 561.833,227.5 511.167,227.5 460.5,227.5C 460.5,176.833 460.5,126.167 460.5,75.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.998}}
          fill="#3bc1e0"
          d="M 654.5,75.5 C 705.5,75.5 756.5,75.5 807.5,75.5C 807.5,126.167 807.5,176.833 807.5,227.5C 756.5,227.5 705.5,227.5 654.5,227.5C 654.5,176.833 654.5,126.167 654.5,75.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.998}}
          fill="#3bc2e0"
          d="M 70.5,271.5 C 97.8635,271.072 125.197,271.572 152.5,273C 177.594,276.744 186.094,290.577 178,314.5C 173.797,320.684 167.964,324.518 160.5,326C 154.925,327.515 149.258,328.515 143.5,329C 130.504,329.5 117.504,329.667 104.5,329.5C 104.5,337.167 104.5,344.833 104.5,352.5C 93.1667,352.5 81.8333,352.5 70.5,352.5C 70.5,325.5 70.5,298.5 70.5,271.5 Z M 104.5,292.5 C 115.505,292.333 126.505,292.5 137.5,293C 146.118,294.558 148.118,298.891 143.5,306C 141.285,307.044 138.952,307.71 136.5,308C 125.839,308.5 115.172,308.667 104.5,308.5C 104.5,303.167 104.5,297.833 104.5,292.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.997}}
          fill="#3bc2e0"
          d="M 236.5,271.5 C 247.853,271.168 259.186,271.501 270.5,272.5C 287.375,299.085 304.375,325.585 321.5,352C 309.486,352.833 297.486,352.666 285.5,351.5C 283.292,347.583 280.959,343.75 278.5,340C 261.833,339.333 245.167,339.333 228.5,340C 225.914,343.836 223.581,347.836 221.5,352C 209.5,352.667 197.5,352.667 185.5,352C 202.896,325.364 219.896,298.53 236.5,271.5 Z M 252.5,296.5 C 253.376,296.369 254.043,296.702 254.5,297.5C 258.122,304.58 262.122,311.413 266.5,318C 257.833,318.667 249.167,318.667 240.5,318C 245.158,311.182 249.158,304.016 252.5,296.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.98}}
          fill="#3bc2df"
          d="M 330.5,271.5 C 342.167,271.5 353.833,271.5 365.5,271.5C 365.5,291.833 365.5,312.167 365.5,332.5C 385.5,332.5 405.5,332.5 425.5,332.5C 425.5,339.167 425.5,345.833 425.5,352.5C 393.833,352.5 362.167,352.5 330.5,352.5C 330.5,325.5 330.5,298.5 330.5,271.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.983}}
          fill="#3bc2e0"
          d="M 436.5,271.5 C 467.5,271.5 498.5,271.5 529.5,271.5C 529.5,278.5 529.5,285.5 529.5,292.5C 509.833,292.5 490.167,292.5 470.5,292.5C 470.5,296.167 470.5,299.833 470.5,303.5C 487.167,303.5 503.833,303.5 520.5,303.5C 520.5,309.167 520.5,314.833 520.5,320.5C 503.833,320.5 487.167,320.5 470.5,320.5C 470.5,324.5 470.5,328.5 470.5,332.5C 491.833,332.5 513.167,332.5 534.5,332.5C 534.5,339.167 534.5,345.833 534.5,352.5C 501.833,352.5 469.167,352.5 436.5,352.5C 436.5,325.5 436.5,298.5 436.5,271.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.974}}
          fill="#3bc2df"
          d="M 541.5,271.5 C 574.167,271.5 606.833,271.5 639.5,271.5C 639.5,278.5 639.5,285.5 639.5,292.5C 628.833,292.5 618.167,292.5 607.5,292.5C 607.5,312.5 607.5,332.5 607.5,352.5C 595.833,352.5 584.167,352.5 572.5,352.5C 572.5,332.5 572.5,312.5 572.5,292.5C 562.167,292.5 551.833,292.5 541.5,292.5C 541.5,285.5 541.5,278.5 541.5,271.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.974}}
          fill="#3bc2df"
          d="M 645.5,271.5 C 678.167,271.5 710.833,271.5 743.5,271.5C 743.5,278.5 743.5,285.5 743.5,292.5C 732.833,292.5 722.167,292.5 711.5,292.5C 711.5,312.5 711.5,332.5 711.5,352.5C 699.833,352.5 688.167,352.5 676.5,352.5C 676.5,332.5 676.5,312.5 676.5,292.5C 666.167,292.5 655.833,292.5 645.5,292.5C 645.5,285.5 645.5,278.5 645.5,271.5 Z"
        />
      </g>
      <g>
        <path
          style={{opacity:0.981}}
          fill="#3bc2e0"
          d="M 754.5,271.5 C 785.833,271.5 817.167,271.5 848.5,271.5C 848.5,278.5 848.5,285.5 848.5,292.5C 828.833,292.5 809.167,292.5 789.5,292.5C 789.5,296.167 789.5,299.833 789.5,303.5C 806.167,303.5 822.833,303.5 839.5,303.5C 839.5,309.167 839.5,314.833 839.5,320.5C 822.833,320.5 806.167,320.5 789.5,320.5C 789.5,324.5 789.5,328.5 789.5,332.5C 810.5,332.5 831.5,332.5 852.5,332.5C 852.5,339.167 852.5,345.833 852.5,352.5C 819.833,352.5 787.167,352.5 754.5,352.5C 754.5,325.5 754.5,298.5 754.5,271.5 Z"
        />
      </g>
    </svg>
  ),
});

export const SupportIcon = createIcon({
  // Doesn't display the full icon without w and h being specified
  displayName: 'BuildIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M20.885 5.547a.703.703 0 00-1.122-.176l-2.7 2.702a.708.708 0 01-.995 0l-1.167-1.169a.702.702 0 010-.994l2.689-2.69a.704.704 0 00-.21-1.138c-2.031-.908-4.566-.435-6.164 1.152-1.358 1.348-1.763 3.455-1.11 5.78a.698.698 0 01-.197.703L2.593 16.4a2.82 2.82 0 103.981 3.983l6.754-7.332a.699.699 0 01.693-.2 7.885 7.885 0 002.03.279c1.469 0 2.757-.475 3.686-1.39 1.72-1.695 1.983-4.57 1.148-6.192zM4.623 19.901a1.407 1.407 0 11-.305-2.797 1.407 1.407 0 01.305 2.797z"
    />
  ),
});

export const StatsIcon = createIcon({
  displayName: 'StatsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M4.57 22.297H3.164a1.055 1.055 0 01-1.055-1.054v-6.328a1.055 1.055 0 011.055-1.055H4.57a1.055 1.055 0 011.055 1.055v6.328a1.055 1.055 0 01-1.055 1.054zM14.414 22.296h-1.406a1.055 1.055 0 01-1.055-1.055V10.695a1.055 1.055 0 011.055-1.055h1.406a1.055 1.055 0 011.055 1.055V21.24a1.055 1.055 0 01-1.055 1.055zM19.336 22.297H17.93a1.055 1.055 0 01-1.055-1.055V5.773A1.055 1.055 0 0117.93 4.72h1.406a1.055 1.055 0 011.055 1.054v15.47a1.055 1.055 0 01-1.055 1.054zM9.492 22.297H8.086a1.055 1.055 0 01-1.055-1.055V2.257a1.055 1.055 0 011.055-1.054h1.406a1.055 1.055 0 011.055 1.054v18.985a1.055 1.055 0 01-1.055 1.055z"
    />
  ),
});

export const WalletIcon = createIcon({
  displayName: 'WalletIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z"
      />
      <path
        fill="currentColor"
        d="M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z"
      />
    </g>
  ),
});

export const ButtonLeft = createIcon({
  displayName: 'ButtonLeft',
  viewBox: '0 0 44 29',
  path: (
    <g width="44" height="29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9.024A5 5 0 0 1 5.606 4.04l32-2.531A5 5 0 0 1 43 6.493v16.014a5 5 0 0 1-5.394 4.984l-32-2.53A5 5 0 0 1 1 19.975V9.024Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
  ),
});

export const ButtonRight = createIcon({
  displayName: 'ButtonRight',
  viewBox: '0 0 44 29',
  path: (
    <svg width="44" height="29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43 9.024a5 5 0 0 0-4.606-4.984l-32-2.531A5 5 0 0 0 1 6.493v16.014a5 5 0 0 0 5.394 4.984l32-2.53A5 5 0 0 0 43 19.975V9.024Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  ),
});