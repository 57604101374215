/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Link,
  Button,
  Input,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import 'assets/css/ScrollBar.css';
import ExpandableCell from './ExpandableCell';

const columnHelper = createColumnHelper();

export default function WelcomeQuestionnaireTable(props) {
  const { tableData, searchInput } = props;
  const [sorting, setSorting] = React.useState([]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [expandedCellId, setExpandedCellId] = React.useState(null);

  let defaultData = tableData;
  const columns = [
    columnHelper.accessor('tiktok_main', {
      id: 'tiktok_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TIKTOK MAIN
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('legal_name', {
      id: 'legal_name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Legal Name
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="60px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('palette_email', {
      id: 'palette_email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Palette Email
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('genre_1', {
      id: 'genre_1',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Genre 1
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('genre_2', {
      id: 'genre_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Genre 2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('genre_3', {
      id: 'genre_3',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Genre 3
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('birthday', {
      id: 'birthday',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Birthday
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('phone_number', {
      id: 'phone_number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Phone Number
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('street_number', {
      id: 'street_number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Street Number
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('city', {
      id: 'city',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          City
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('state', {
      id: 'state',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          State
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('zip', {
      id: 'zip',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Zip
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Country
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('address_alternate', {
      id: 'address_alternate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Address Alternate
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('pronouns', {
      id: 'pronouns',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Pronouns
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('race', {
      id: 'race',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Race
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('relationship_status', {
      id: 'relationship_status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Relationship Status
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="60px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('education_status', {
      id: 'education_status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Education Status
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('is_parent', {
      id: 'is_parent',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Is Parent?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('is_lgbtq', {
      id: 'is_lgbtq',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Is LGBTQIA+?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('is_political', {
      id: 'is_political',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Is Political?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('is_sex_positive', {
      id: 'is_sex_positive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Is Sex Positive?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('has_pet', {
      id: 'has_pet',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Has Pet?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('has_amazon_storefront', {
      id: 'has_amazon_storefront',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Has Amazon StoreFront?
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });


  React.useEffect(() => {
    if (searchInput.length > 0) {
      const filteredData = defaultData.filter((item) =>
        item['tiktok_main'].includes(searchInput),
      );
      setData(filteredData);
    } else {
      setData([...defaultData]);
    }
  }, [searchInput])

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        justifyContent={{ xl: 'space-between' }}
        gap={{ base: '20px', xl: '20px' }}
        padding={{ base: '20px', xl: '20px' }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Welcome Questionnaire
        </Text>
      </Flex>
      <Box className="scroll-wrapper">
        <Box className="custom-scrollbar">
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" mt="5">
        <Button
          pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
          color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex - 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex - 1);
          }}
        >
          Previous
        </Button>
        <Text p="1">
          Page{' '}
          {table.getPageOptions().length > 0
            ? table.getState().pagination.pageIndex + 1
            : 0}{' '}
          of {table.getPageOptions().length}
        </Text>
        <Button
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex + 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex + 1);
          }}
          pointerEvents={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
          }
          color={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
          }
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
