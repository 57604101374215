// Chakra imports
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import LineChart from 'components/charts/LineChart';
import React from 'react';
import LineChartOptions from 'variables/LineChartOptions';
import lineChartData from '../variables/castingopp-counts.json';

export default function CastingOppLineChart() {
  const chartOptions = LineChartOptions(lineChartData);

  const chartData = [
    {
      name: 'Casting Opps Submitted',
      data: lineChartData[0].data.map((item) => item.value),
    },
  ];

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
    >
      <Text me="auto" fontSize="md" color={textColor} fontWeight="600">
        Casting Opps Submitted
      </Text>
      <Box minH="260px" minW="75%" mt="auto">
        <LineChart chartData={chartData} chartOptions={chartOptions} />
      </Box>
    </Card>
  );
}
