import {
    Flex,
    Text,
    useColorModeValue,
    Box,
    Input,
    Button,
    Tbody,
    Thead,
    Td,
    Tr,
    Th,
    Table,
    Select
  } from '@chakra-ui/react';
  
  import React from 'react';
  import Card from 'components/card/Card.js';
  import AccountsHQDropdowns from '../variables/dropdowns/scratch-pad.json'
  import AccountsHQData from 'views/dashboard/accountshq/variables/AccountsHQData.json'
  
  import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table';
  
  const columnHelper = createColumnHelper();
  
  export default function ScratchPad() {
    const [sorting, setSorting] = React.useState([]);
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: 5,
    });
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  
    const [curAccountsHQCol, setCurAccountsHQCol] = React.useState(AccountsHQDropdowns[0]);

    const [accountsHQColValues, setAccountsHQColValues] = React.useState(
      AccountsHQData.reduce((acc, item) => {
        acc[item.tiktok_main] = item[curAccountsHQCol.id];
        return acc;
      }, {})
    );
  
    const handleMarkingChange = (e, creator) => {
        setAccountsHQColValues((prevValues) => ({
        ...prevValues,
        [creator]: e.target.value,
      }));
    }
    
    React.useEffect(() => { 
        setAccountsHQColValues(AccountsHQData.reduce((acc, item) => {
          acc[item.tiktok_main] = item[curAccountsHQCol.id];
          return acc;
        }, {}));
    }, [curAccountsHQCol]);
  
    const columns = React.useMemo(() => [
      columnHelper.accessor('tiktok_main', {
        id: 'tiktok_main',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            CREATOR
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700" w="120px">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('marking', {
        id: 'marking',
        header: () => (
          <Select fontSize="12px" value={curAccountsHQCol.name} onChange={(e) => setCurAccountsHQCol(AccountsHQDropdowns.filter(item => item.name === e.target.value)[0])}>
            {AccountsHQDropdowns.map(item => {
                return <option value={item.name}>{item.name}</option>
            })}
          </Select>
        ),
        cell: (info) => (
          <Input
            defaultValue={accountsHQColValues[info.row.original.tiktok_main] || ''}
            onBlur={(e) => handleMarkingChange(e, info.row.original.tiktok_main)}
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            w="120px"
          />
        ),
        enableSorting: false,
      }),
    ], [accountsHQColValues, curAccountsHQCol, textColor]);
  
  
    const table = useReactTable({
      data: AccountsHQData,
      columns,
      state: {
        sorting,
        pagination,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });
  
    return (
      <>
        <Card flexDirection="column" w="100%" align="center">
          <Flex
            flexDirection={{
              sm: 'column',
              md: 'row',
            }}
            alignItems={{ xl: 'center' }}
            justifyContent={{ xl: 'space-between' }}
            pt="5px"
          >
            <Text fontSize="22px" color={textColor} fontWeight="600">
                Scratch Pad
            </Text>
  
            <Button variant="palette" size={{ sm: 'sm', md: 'md' }}>
              Save
            </Button>
          </Flex>
  
          <Box>
            <Box>
              <Table variant="simple" color="gray.500" mb="24px" mt="12px">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <Th
                            key={header.id}
                            colSpan={header.colSpan}
                            pe="10px"
                            borderColor={borderColor}
                            cursor="pointer"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <Flex
                              justifyContent="space-between"
                              align="center"
                              fontSize={{ sm: '10px', lg: '12px' }}
                              color="gray.400"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted()] ?? null}
                            </Flex>
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table
                    .getRowModel()
                    .rows.slice(0, 5)
                    .map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                fontSize={{ sm: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor="transparent"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Box display="flex" justifyContent="end" alignItems="center" mt="5">
            <Button
              pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
              color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
              onClick={() => {
                setPagination({
                  pageIndex: table.getState().pagination.pageIndex - 1,
                  pageSize: 5,
                });
                table.setPageIndex(table.getState().pagination.pageIndex - 1);
              }}
            >
              Previous
            </Button>
            <Text p="1">
              Page{' '}
              {table.getPageOptions().length > 0
                ? table.getState().pagination.pageIndex + 1
                : 0}{' '}
              of {table.getPageOptions().length}
            </Text>
            <Button
              onClick={() => {
                setPagination({
                  pageIndex: table.getState().pagination.pageIndex + 1,
                  pageSize: 5,
                });
                table.setPageIndex(table.getState().pagination.pageIndex + 1);
              }}
              pointerEvents={
                pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
              }
              color={
                pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
              }
            >
              Next
            </Button>
          </Box>
        </Card>
      </>
    );
  }
  