import { Flex, Text, Box, Tooltip, Icon } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import { TbRotate360 } from 'react-icons/tb';
import * as React from 'react';

export default function StatCard(props) {
  const { platform1, platform2, data, searchInput, icon } = props;
  const [isRotated, setIsRotated] = React.useState(false);

  const [statData, setStatData] = React.useState(data);

  const [mainData, setMainData] = React.useState(
    data[0]['stats'].filter((item) => item['platform'] === platform1)[0],
  );
  const [secondaryData, setSecondaryData] = React.useState(
    data[0]['stats'].filter((item) => item['platform'] === platform2)[0],
  );

  const handleRotation = () => {
    setIsRotated((prev) => !prev);
  };

  React.useEffect(() => {
    if (searchInput.length > 0) {
      const filteredData = data.filter((item) =>
        item['tiktok_main'].includes(searchInput),
      );
      setStatData(filteredData);
    } else {
      setStatData([...data]);
    }
  }, [searchInput, data]);

  React.useEffect(() => {
    if (statData.length > 0) {
      setMainData(
        statData[0]['stats'].filter(
          (item) => item['platform'] === platform1,
        )[0],
      );
      setSecondaryData(
        statData[0]['stats'].filter(
          (item) => item['platform'] === platform2,
        )[0],
      );
    }
  }, [statData, platform1, platform2]);

  return (
    <Card direction="column" w="100%" h="520px" position="relative">
      <Icon as={icon} position="absolute" w="80%" h="80%" opacity="0.1" left="50%" top="50%" transform="translate(-50%, -50%)"/>
      <Box
        position="relative"
        w="100%"
        h="100%"
        display="flex"
        flexDirection="column"
        style={{
          transform: isRotated ? 'rotateY(180deg)' : 'rotateY(0deg)',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.6s',
        }}
      >
        <Box
          position="absolute"
          style={{ backfaceVisibility: 'hidden' }}
          display="flex"
          flexDirection="column"
          padding="20px"
          w="100%"
        >
          {mainData.handle.includes('@') ? (
            <>
              <Flex
                px={{ base: '0px', '2xl': '10px' }}
                justifyContent="space-between"
                w="100%"
                mb="20px"
                alignItems="center"
              >
                <Text fontSize="22px" fontWeight="600" mt="4px" width="75%">
                  {mainData.platform} Stats for {mainData.handle}
                </Text>
                <Tooltip label="Check second account">
                  <span>
                    <TbRotate360
                      size="30px"
                      onClick={handleRotation}
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
              </Flex>
              <Flex direction="column" gap="20px">
                {mainData.data.map((item) => {
                  return (
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold">{item.name}</Text>
                      <Text>{item.value}</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </>
          ) : (
            <Flex
              px={{ base: '0px', '2xl': '10px' }}
              justifyContent="space-between"
              w="100%"
              mb="20px"
              alignItems="center"
            >
              <Text fontSize="22px" fontWeight="600" mt="4px" width="75%">
                No account for {mainData.platform}
              </Text>
              <Tooltip label="Check second account">
                <span>
                  <TbRotate360
                    size="30px"
                    onClick={handleRotation}
                    cursor="pointer"
                  />
                </span>
              </Tooltip>
            </Flex>
          )}
        </Box>

        <Box
          position="absolute"
          style={{ backfaceVisibility: 'hidden' }}
          transform="rotateY(180deg)"
          display="flex"
          flexDirection="column"
          padding="20px"
          w="100%"
        >
          {secondaryData.handle.includes('@') ? (
            <>
              <Flex
                px={{ base: '0px', '2xl': '10px' }}
                justifyContent="space-between"
                w="100%"
                mb="20px"
                alignItems="center"
              >
                <Text fontSize="22px" fontWeight="600" mt="4px" width="75%">
                  {secondaryData.platform} Stats for {secondaryData.handle}
                </Text>
                <Tooltip label="Check first account">
                  <span>
                    <TbRotate360
                      size="30px"
                      onClick={handleRotation}
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
              </Flex>
              <Flex direction="column" gap="20px">
                {secondaryData.data.map((item) => {
                  return (
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold">{item.name}</Text>
                      <Text>{item.value}</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </>
          ) : (
            <Flex
              px={{ base: '0px', '2xl': '10px' }}
              justifyContent="space-between"
              w="100%"
              mb="20px"
              alignItems="center"
            >
              <Text
                fontSize="22px"
                fontWeight="600"
                mt="4px"
                width="75%"
                textAlign="left"
              >
                No account for {secondaryData.platform}
              </Text>
              <Tooltip label="Check first account">
                <span>
                  <TbRotate360
                    size="30px"
                    onClick={handleRotation}
                    cursor="pointer"
                  />
                </span>
              </Tooltip>
            </Flex>
          )}
        </Box>
      </Box>
    </Card>
  );
}
