import React from 'react';

// Chakra imports
import { Box, Flex, Text, useColorModeValue, Select } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card.js';
import barChartOptions from 'variables/BarChartOptions';
import lineChartData from '../variables/castingopp-managers.json';

export default function ManagerBarChart(props) {
  const { ...rest } = props;

  const chartOptions = barChartOptions(lineChartData, "number");
  const chartData = [
    {
      name: 'Casting Opps Submitted',
      data: lineChartData.map((item) => item.value),
    },
  ];

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" justifyContent="space-between" px="10px" pt="5px">
            <Text me="auto" fontSize="md" color={textColor} fontWeight="600">
                Casting Opps Submitted
            </Text>

            <Select w={{sm: "50%", md: "30%"}}>
              <option value="Last Month">Last Month</option>
              <option value="Last 3 Months">Last 3 Months</option>
              <option value="Last 6 Months">Last 6 Months</option>
            </Select>
      </Flex>
      <Box h="180px" mt="auto">
        <BarChart chartData={chartData} chartOptions={chartOptions} />
      </Box>
    </Card>
  );
}
