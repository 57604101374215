import {
  Modal,
  Button,
  Input,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Text,
  Select,
  Textarea,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/DatePicker.css';
import * as React from 'react';
import AllStatus from '../variables/dropdowns/accountshq-status.json';
import Managers from '../variables/dropdowns/managers.json';

export default function AccountsHQModal(props) {
  const {
    isOpen,
    onClose,
    isDeleteOpen,
    onDeleteClose,
    isEdited,
    creatorInfo,
  } = props;

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [formValues, setFormValues] = React.useState({});

  React.useEffect(() => {
    setFormValues({
      tiktok_main: creatorInfo.tiktok_main ? creatorInfo.tiktok_main : '',
      status: creatorInfo.status ? creatorInfo.status : '',
      palette_point: creatorInfo.palette_point ? creatorInfo.palette_point : '',
      manager: creatorInfo.manager ? creatorInfo.manager : '',
      co_manager: creatorInfo.co_manager ? creatorInfo.co_manager : '',
      sr_coordinator: creatorInfo.sr_coordinator
        ? creatorInfo.sr_coordinator
        : '',
      coordinator: creatorInfo.coordinator ? creatorInfo.coordinator : '',
      coordinator_2: creatorInfo.coordinator_2 ? creatorInfo.coordinator_2 : '',
      mgmt_notes: creatorInfo.mgmt_notes ? creatorInfo.mgmt_notes : '',
      rate_notes: creatorInfo.rate_notes ? creatorInfo.rate_notes : '',
      palette_split: creatorInfo.palette_split ? creatorInfo.palette_split : '',
      signing_date: creatorInfo.signing_date ? creatorInfo.signing_date : '',
      contracting_entity: creatorInfo.contracting_entity
        ? creatorInfo.contracting_entity
        : '',
      name: creatorInfo.name ? creatorInfo.name : '',

      tiktok_2: creatorInfo.tiktok_2 ? creatorInfo.tiktok_2 : '',
      ig_main: creatorInfo.ig_main ? creatorInfo.ig_main : '',
      ig_2: creatorInfo.ig_2 ? creatorInfo.ig_2 : '',
      yt_main: creatorInfo.yt_main ? creatorInfo.yt_main : '',
      yt_2: creatorInfo.yt_2 ? creatorInfo.yt_2 : '',
      snapchat: creatorInfo.snapchat ? creatorInfo.snapchat : '',
      twitter: creatorInfo.twitter ? creatorInfo.twitter : '',
      facebook: creatorInfo.facebook ? creatorInfo.facebook : '',
      tiktok_main_audio_promo: creatorInfo.tiktok_main_audio_promo
        ? creatorInfo.tiktok_main_audio_promo
        : '',
      tiktok_2_audio_promo: creatorInfo.tiktok_2_audio_promo
        ? creatorInfo.tiktok_2_audio_promo
        : '',
      audio_genre_1: creatorInfo.audio_genre_1 ? creatorInfo.audio_genre_1 : '',
      audio_genre_2: creatorInfo.audio_genre_2 ? creatorInfo.audio_genre_2 : '',
      audio_genre_3: creatorInfo.audio_genre_3 ? creatorInfo.audio_genre_3 : '',
      metropolis: creatorInfo.metropolis ? creatorInfo.metropolis : '',
      agenda: creatorInfo.agenda ? creatorInfo.agenda : '',
      analytics_folder: creatorInfo.analytics_folder
        ? creatorInfo.analytics_folder
        : '',
      google_folder: creatorInfo.google_folder ? creatorInfo.google_folder : '',
    });
  }, [creatorInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={{ sm: 'xs', md: 'xl' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)',
          }}
        />
        <ModalContent mt="30px">
          <ModalHeader>{isEdited ? 'Edit Creator' : 'Add Creator'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="10px">
              <Flex flexDirection="column">
                <FormControl>
                  <FormLabel>TikTok Main</FormLabel>
                  <Input
                    placeholder="TikTok Main"
                    name="tiktok_main"
                    onChange={handleInputChange}
                    value={formValues.tiktok_main}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Status</FormLabel>
                  <Select
                    placeholder="Status"
                    onChange={handleInputChange}
                    value={formValues.status}
                    name="status"
                  >
                    {AllStatus.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Palette Point</FormLabel>
                  <Select
                    placeholder="Palette Point"
                    onChange={handleInputChange}
                    value={formValues.palette_point}
                    name="palette_point"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Manager</FormLabel>
                  <Select
                    placeholder="Manager"
                    onChange={handleInputChange}
                    value={formValues.manager}
                    name="manager"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Co-Manager</FormLabel>
                  <Select
                    placeholder="Co-Manager"
                    onChange={handleInputChange}
                    value={formValues.co_manager}
                    name="co_manager"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Sr. Coordinator</FormLabel>
                  <Select
                    placeholder="Sr. Coordinator"
                    onChange={handleInputChange}
                    value={formValues.sr_coordinator}
                    name="sr_coordinator"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Coordinator</FormLabel>
                  <Select
                    placeholder="Coordinator"
                    onChange={handleInputChange}
                    value={formValues.coordinator}
                    name="coordinator"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Coordinator #2</FormLabel>
                  <Select
                    placeholder="Coordinator #2"
                    onChange={handleInputChange}
                    value={formValues.coordinator_2}
                    name="coordinator_2"
                  >
                    {Managers.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>MGMT Notes</FormLabel>
                  <Textarea
                    placeholder="MGMT Notes"
                    name="mgmt_notes"
                    onChange={handleInputChange}
                    value={formValues.mgmt_notes}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Rate Notes</FormLabel>
                  <Textarea
                    placeholder="Rate Notes"
                    name="rate_notes"
                    onChange={handleInputChange}
                    value={formValues.rate_notes}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Palette Split</FormLabel>
                  <Input
                    placeholder="Palette Split"
                    name="palette_split"
                    onChange={handleInputChange}
                    value={formValues.palette_split}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Signing Date</FormLabel>
                  <Input
                    placeholder="Signing Date"
                    name="signing_date"
                    onChange={handleInputChange}
                    value={formValues.signing_date}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Contracting Entity</FormLabel>
                  <Input
                    placeholder="Contracting Entity"
                    name="contracting_entity"
                    onChange={handleInputChange}
                    value={formValues.contracting_entity}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder="Name"
                    name="name"
                    onChange={handleInputChange}
                    value={formValues.name}
                  />
                </FormControl>
              </Flex>
              <Flex flexDirection="column">
                <FormControl>
                  <FormLabel>TikTok 2</FormLabel>
                  <Input
                    placeholder="TikTok 2"
                    name="tiktok_2"
                    onChange={handleInputChange}
                    value={formValues.tiktok_2}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>IG Main</FormLabel>
                  <Input
                    placeholder="IG Main"
                    onChange={handleInputChange}
                    value={formValues.ig_main}
                    name="ig_main"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>IG 2</FormLabel>
                  <Input
                    placeholder="IG 2"
                    onChange={handleInputChange}
                    value={formValues.ig_2}
                    name="ig_2"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>YT Main</FormLabel>
                  <Input
                    placeholder="YT Main"
                    onChange={handleInputChange}
                    value={formValues.yt_main}
                    name="yt_main"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>YT 2</FormLabel>
                  <Input
                    placeholder="YT 2"
                    onChange={handleInputChange}
                    value={formValues.yt_2}
                    name="yt_2"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Snapchat</FormLabel>
                  <Input
                    placeholder="Snapchat"
                    onChange={handleInputChange}
                    value={formValues.snapchat}
                    name="Snapchat"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Twitter</FormLabel>
                  <Input
                    placeholder="Twitter"
                    onChange={handleInputChange}
                    value={formValues.twitter}
                    name="Twitter"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Facebook</FormLabel>
                  <Input
                    placeholder="Facebook"
                    onChange={handleInputChange}
                    value={formValues.facebook}
                    name="Facebook"
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>TikTok Main Audio Promo</FormLabel>
                  <Select
                    placeholder="TikTok Main Audio Promo"
                    name="tiktok_main_audio_promo"
                    onChange={handleInputChange}
                    value={formValues.tiktok_main_audio_promo}
                  >
                    <option value="Yes">Yes</option>;
                    <option value="No">No</option>;
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>TikTok 2 Audio Promo</FormLabel>
                  <Select
                    placeholder="TikTok 2 Audio Promo"
                    name="tiktok_2_audio_promo"
                    onChange={handleInputChange}
                    value={formValues.tiktok_2_audio_promo}
                  >
                    <option value="Yes">Yes</option>;
                    <option value="No">No</option>;
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Audio Genre 1</FormLabel>
                  <Input
                    placeholder="Audio Genre 1"
                    name="audio_genre_1"
                    onChange={handleInputChange}
                    value={formValues.audio_genre_1}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Audio Genre 2</FormLabel>
                  <Input
                    placeholder="Audio Genre 2"
                    name="audio_genre_2"
                    onChange={handleInputChange}
                    value={formValues.audio_genre_2}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Audio Genre 3</FormLabel>
                  <Input
                    placeholder="Audio Genre 3"
                    name="audio_genre_3"
                    onChange={handleInputChange}
                    value={formValues.audio_genre_3}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Metropolis</FormLabel>
                  <Input
                    placeholder="Metropolis"
                    name="metropolis"
                    onChange={handleInputChange}
                    value={formValues.metropolis}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Agenda</FormLabel>
                  <Input
                    placeholder="Agenda"
                    name="agenda"
                    onChange={handleInputChange}
                    value={formValues.agenda}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Analytics Folder</FormLabel>
                  <Input
                    placeholder="Analytics Folder"
                    name="analytics_folder"
                    onChange={handleInputChange}
                    value={formValues.analytics_folder}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Google Folder</FormLabel>
                  <Input
                    placeholder="Google Folder"
                    name="google_folder"
                    onChange={handleInputChange}
                    value={formValues.google_folder}
                  />
                </FormControl>
              </Flex>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="palette" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)',
          }}
        />
        <ModalContent>
          <ModalHeader>Delete the Creator</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Are you sure to delete the creator? This cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button color="red.500" mr={3}>
              Yes
            </Button>
            <Button onClick={onDeleteClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
