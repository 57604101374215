/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import 'assets/css/ScrollBar.css';
import AccountsHQModal from './AccountsHQModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import ExpandableCell from './ExpandableCell';

const columnHelper = createColumnHelper();

export default function AccountsHQTable(props) {
  const { tableData, searchInput, setSearchInput} = props;
  const [sorting, setSorting] = React.useState([]);
  const [creatorIsEdited, setCreatorIsEdited] = React.useState(false);
  const [curCreatorInfo, setCurCreatorInfo] = React.useState({});
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [expandedCellId, setExpandedCellId] = React.useState(null);

  const handleEditCreator = (rowData) => {
    const curCreatorData = tableData.filter(item => item.tiktok_main === rowData.tiktok_main)[0];
    setCurCreatorInfo(curCreatorData);
    setCreatorIsEdited(true);
    onOpen();
  };

  const handleCreateDeal = () => {
    setCurCreatorInfo({});
    setCreatorIsEdited(false);
    onOpen();
  };

  let defaultData = tableData;
  const columns = [
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <Flex>
            <Icon
              mt="6px"
              as={MdEdit}
              color="palette.500"
              w="18px"
              h="18px"
              me="10px"
              cursor="pointer"
              onClick={() => handleEditCreator(rowData)}
            />
            <Icon
              mt="6px"
              as={MdDelete}
              color="red"
              w="18px"
              h="18px"
              me="10px"
              cursor="pointer"
              onClick={onDeleteOpen}
            />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('tiktok_main', {
      id: 'tiktok_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TIKTOK MAIN
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('palette_point', {
      id: 'palette_point',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          PALETTE POINT
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('manager', {
      id: 'manager',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          MANAGER
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('co_manager', {
      id: 'co_manager',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CO-MANAGER
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('sr_coordinator', {
      id: 'sr_coordinator',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          SR. COORDINATOR
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('coordinator', {
      id: 'coordinator',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          COORDINATOR
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('coordinator_2', {
      id: 'coordinator_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          COORDINATOR #2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('mgmt_notes', {
      id: 'mgmt_notes',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          MGMT NOTES
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="120px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('rate_notes', {
      id: 'rate_notes',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          RATE NOTES
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="120px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('palette_split', {
      id: 'palette_split',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          PALETTE SPLIT
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('signing_date', {
      id: 'signing_date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          SIGNING DATE
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('contracting_entity', {
      id: 'contracting_entity',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Contracting Entity
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Name
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        justifyContent={{ xl: 'space-between' }}
        gap={{ base: '20px', xl: '20px' }}
        padding={{ base: '20px', xl: '20px' }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          AccountsHQ
        </Text>
        <Flex
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
          alignItems={{ xl: 'center' }}
          justifyContent={{ xl: 'end' }}
          gap={{ base: '20px', xl: '20px' }}
        >
          <Button
            variant="palette"
            onClick={handleCreateDeal}
            size={{ sm: 'sm', md: 'md' }}
          >
            Add
          </Button>
          <AccountsHQModal
            isOpen={isOpen}
            onClose={onClose}
            isDeleteOpen={isDeleteOpen}
            onDeleteClose={onDeleteClose}
            isEdited={creatorIsEdited}
            creatorInfo={curCreatorInfo}
          />
          <Input
            size={{ sm: 'sm', md: 'md' }}
            placeholder="Search by TikTok Main.."
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              if (e.target.value.length > 0) {
                const filteredData = defaultData.filter((item) =>
                  item['tiktok_main'].includes(e.target.value),
                );
                setData(filteredData);
              } else {
                setData([...defaultData]);
              }
            }}
          ></Input>
        </Flex>
      </Flex>
      <Box className="scroll-wrapper">
        <Box className="custom-scrollbar">
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id} height="50px">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" mt="5">
        <Button
          pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
          color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex - 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex - 1);
          }}
        >
          Previous
        </Button>
        <Text p="1">
          Page{' '}
          {table.getPageOptions().length > 0
            ? table.getState().pagination.pageIndex + 1
            : 0}{' '}
          of {table.getPageOptions().length}
        </Text>
        <Button
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex + 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex + 1);
          }}
          pointerEvents={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
          }
          color={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
          }
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
