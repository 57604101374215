/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box,  SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';

import AccountsHQTable from 'views/dashboard/accountshq/components/AccountsHQTable';
import WelcomeQuestionnaireTable from 'views/dashboard/accountshq/components/WelcomeQuestionnaireTable';
import AccountsInfoTable from 'views/dashboard/accountshq/components/AccountsInfoTable';
import StatCard from 'views/dashboard/accountshq/components/StatCard';
import StatusBarChart from 'views/dashboard/accountshq/components/StatusBarChart';
import BarChart from 'views/dashboard/uids/components/CreatorBarChart';
import ViewsLineChart from 'views/dashboard/accountshq/components/ViewsLineChart';
import TikTokViewsData from 'views/dashboard/accountshq/variables/tiktok-views.json';
import IGViewsData from 'views/dashboard/accountshq/variables/ig-views.json';
import YoutubeViewsData from 'views/dashboard/accountshq/variables/youtube-views.json';

import AccountsHQData from 'views/dashboard/accountshq/variables/AccountsHQData.json';

export default function AccountsHQDashboard() {
  const [searchInput, setSearchInput] = React.useState('');

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px">
        <ViewsLineChart data={TikTokViewsData} icon={FaTiktok}/>
        <ViewsLineChart data={IGViewsData} icon={FaInstagram}/>
        <ViewsLineChart data={YoutubeViewsData} icon={FaYoutube}/>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <StatusBarChart />
        <BarChart />
      </SimpleGrid>

      <AccountsHQTable tableData={AccountsHQData} searchInput={searchInput} setSearchInput={setSearchInput}/>

      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px" mt="20px">
        <StatCard platform1="TikTok Main" platform2="TikTok 2" data={AccountsHQData} searchInput={searchInput} icon={FaTiktok}/>
        <StatCard platform1="IG Main" platform2="IG 2" data={AccountsHQData} searchInput={searchInput} icon={FaInstagram}/>
        <StatCard platform1="YT Main" platform2="YT 2" data={AccountsHQData} searchInput={searchInput} icon={FaYoutube}/>
      </SimpleGrid>

      <SimpleGrid gap="20px" mb="20px">
        <WelcomeQuestionnaireTable tableData={AccountsHQData} searchInput={searchInput}/>
        <AccountsInfoTable tableData={AccountsHQData} searchInput={searchInput}/>
      </SimpleGrid>
    </Box>
  );
}
