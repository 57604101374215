// Chakra imports
import { Flex, Text, useColorModeValue, Select } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import PieChart from 'components/charts/PieChart';
import UIDsCategories from '../variables/dropdowns/uids-category.json';
import PieChartOptions from 'variables/PieChartOptions';
import React from 'react';

export default function CategoryPieChart() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const chartOptions = PieChartOptions(UIDsCategories);

  return (
    <Card p="20px" align="center" direction="column" w="100%" h="250px">
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Deals Category
        </Text>

        <Select w={{sm: "50%", md: "30%"}}>
          <option value="Last Month">Last Month</option>
          <option value="Last 3 Months">Last 3 Months</option>
          <option value="Last 6 Months">Last 6 Months</option>
        </Select>
      </Flex>

      <PieChart
        h="100%"
        w="100%"
        chartData={UIDsCategories.map((item) => item.percent).filter(
          (item) => item > 0,
        )}
        chartOptions={chartOptions}
      />
    </Card>
  );
}
