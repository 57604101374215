import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdPerson,
  MdLock,
  MdOutlineHighQuality 
} from 'react-icons/md';
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaMoneyBillWave } from "react-icons/fa";
import { CiMemoPad } from "react-icons/ci";

// Admin Imports
import UIDsDashboard from 'views/dashboard/uids';
import CastingOppDashboard from 'views/dashboard/castingopp'
import AccountsHQDashboard from 'views/dashboard/accountshq'
import WorkbookDashboard from 'views/dashboard/workbook';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
  {
    name: 'AccountsHQ',
    layout: '/dashboard',
    path: '/accountshq',
    icon: <Icon as={MdOutlineHighQuality} width="20px" height="20px" color="inherit" />,
    component: <AccountsHQDashboard />,
  },
  {
    name: 'UIDs',
    layout: '/dashboard',
    path: '/uids',
    icon: <Icon as={FaMoneyBillWave} width="20px" height="20px" color="inherit" />,
    component: <UIDsDashboard />,
  },
  {
    name: 'Casting Opps',
    layout: '/dashboard',
    path: '/casting-opps',
    icon: <Icon as={HiOutlineLightBulb} width="20px" height="20px" color="inherit" />,
    component: <CastingOppDashboard />,
  },
  {
    name: 'Manager Workbook',
    layout: '/dashboard',
    path: '/manager-workbook',
    icon: <Icon as={CiMemoPad} width="20px" height="20px" color="inherit" />,
    component: <WorkbookDashboard />,
  },
  /*
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  }*/
];

export default routes;
