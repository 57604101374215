/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React from 'react';
import { MdAttachMoney, MdBarChart } from 'react-icons/md';
import UIDsTable from 'views/dashboard/uids/components/UIDsTable';
import PieCard from 'views/dashboard/uids/components/CategoryPieChart';
import BarChart from 'views/dashboard/uids/components/CreatorBarChart';
import UIDsTableData from 'views/dashboard/uids/variables/UIDsTableData.json';
import RightSideBar from 'views/dashboard/uids/components/RightSideBar';
import { FaAward } from 'react-icons/fa6';
export default function UIDsDashboard() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('palette.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  let bgButton = 'linear-gradient(135deg, #33B2D4 0%, #1986B4 100%)';

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Current Month Deals"
          value="378"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          growth="+23%"
          name="Total Invoiced Amount"
          value="$574.34"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Total Creator Pay"
          value="$542.39"
          growth="+23%"
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <PieCard />
        <BarChart />
      </SimpleGrid>

      <SimpleGrid gap="20px" mb="20px">
        <UIDsTable tableData={UIDsTableData} />
      </SimpleGrid>

      <Tooltip label="Manager Leaderboard">
        <Button
          h="60px"
          w="60px"
          zIndex="99"
          bg={bgButton}
          position="fixed"
          variant="no-effects"
          right="35px"
          bottom="30px"
          border="1px solid"
          borderColor="white"
          borderRadius="50px"
          onClick={onOpen}
          display="flex"
          p="0px"
          align="center"
          justify="center"
        >
          <Icon h="24px" w="24px" color="white" as={FaAward} />
        </Button>
      </Tooltip>

      <RightSideBar isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}
