import { Box, SimpleGrid } from '@chakra-ui/react';

import React from 'react';
import CastingOppTable from 'views/dashboard/castingopp/components/CastingOppTable';
import LineChart from 'views/dashboard/castingopp/components/CastingOppLineChart';
import ManagerBarChart from 'views/dashboard/castingopp/components/ManagerBarChart';
import CastingOppTableData from 'views/dashboard/castingopp/variables/castingopp-creators.json';
import CastingOppsCard from './components/CastingOppsCard';
import castingOppData from 'views/dashboard/castingopp/variables/castingopp-data.json';

export default function CastingOppDashboard() {
  const activeCastingOpps = castingOppData.filter((item) => item.is_active);
  const passiveCastingOpps = castingOppData.filter((item) => !item.is_active);

  const [curCastingOpp, setCurCastingOpp] = React.useState(
    activeCastingOpps[0],
  );

  const [curPassiveCastingOpp, setCurPassiveCastingOpp] = React.useState(
    passiveCastingOpps[0],
  );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <LineChart />
        <ManagerBarChart />
      </SimpleGrid>

      <SimpleGrid
        templateColumns={{ base: '1fr', md: '1fr 2fr', xl: '1fr 2fr' }}
        gap="20px"
        mb="20px"
      >
        <CastingOppsCard
          castingOpps={activeCastingOpps}
          curCastingOpp={curCastingOpp}
          setCurCastingOpp={setCurCastingOpp}
        />
        <CastingOppTable
          tableData={CastingOppTableData}
          curCastingOpp={curCastingOpp}
        />
      </SimpleGrid>

      <SimpleGrid
        templateColumns={{ base: '1fr', md: '2fr 1fr', xl: '2fr 1fr' }}
        gap="20px"
        mb="20px"
      >
        <CastingOppTable
          tableData={CastingOppTableData}
          curCastingOpp={curPassiveCastingOpp}
        />
        <CastingOppsCard
          castingOpps={passiveCastingOpps}
          curCastingOpp={curPassiveCastingOpp}
          setCurCastingOpp={setCurPassiveCastingOpp}
        />
      </SimpleGrid>
    </Box>
  );
}
