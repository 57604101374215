import axios from 'axios';
import Cookies from 'universal-cookie';

export default function sendPostRequest(data, endpoint) {
  const cookies = new Cookies();

  // http://localhost:8000
  return axios.post(`https://api.palettemgmt.com/${endpoint}`, data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': cookies.get('csrftoken'),
    },
  });
}
