import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Box, Text, Link } from '@chakra-ui/react';

const ExpandedContentOverlay = ({
  isVisible,
  content,
  positionStyles,
  overlayRef,
  color,
}) => {
  if (!isVisible) return null;

  return (
    <Box
      position="fixed"
      top={positionStyles.top}
      left={positionStyles.left}
      boxShadow="md"
      padding="8px"
      zIndex="1000"
      backgroundColor="white"
      border="2px solid"
      borderColor={color}
      ref={overlayRef}
    >
      <Text fontSize="sm" fontWeight="700" color={color}>
        {content}
      </Text>
    </Box>
  );
};

const ExpandableCell = ({
  textColor,
  value,
  width,
  cellId,
  expandedCellId,
  setExpandedCellId,
  hasLink = false,
}) => {
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  const [positionStyles, setPositionStyles] = useState({
    top: '0px',
    left: '0px',
    width: 'auto',
  });
  const isExpanded = cellId === expandedCellId;

  const handleDoubleClick = useCallback(() => {
    setExpandedCellId(isExpanded ? null : cellId);
  }, [isExpanded, cellId, setExpandedCellId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target) &&
        isExpanded
      ) {
        setExpandedCellId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, setExpandedCellId, isExpanded]);

  useEffect(() => {
    if (containerRef.current && isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      setPositionStyles({
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
      });
    }
  }, [isExpanded]);

  return (
    <>
      <Box
        ref={containerRef}
        position="relative"
        onDoubleClick={handleDoubleClick}
        width={width}
        overflow="hidden"
      >
        {!hasLink ? (
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            isTruncated={!isExpanded}
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {value}
          </Text>
        ) : (
          <Link
            color="blue"
            fontSize="sm"
            fontWeight="700"
            href={value}
            target='_blank'
          >
            Link
          </Link>
        )}
      </Box>
      <ExpandedContentOverlay
        overlayRef={overlayRef}
        isVisible={isExpanded}
        content={value}
        positionStyles={positionStyles}
        color={textColor}
      />
    </>
  );
};

export default ExpandableCell;
