import {
  Modal,
  Button,
  Input,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Text,
  Select,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/DatePicker.css';
import * as React from 'react';
import AllTypes from '../variables/dropdowns/castingopp-types.json';
import Managers from 'views/dashboard/accountshq/variables/dropdowns/managers.json';

export default function AccountsHQModal(props) {
  const {
    isOpen,
    onClose,
    isDeleteOpen,
    onDeleteClose,
    isEdited,
    isRestoreOpen,
    onRestoreClose,
    castingOppInfo,
  } = props;

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [formValues, setFormValues] = React.useState({});

  React.useEffect(() => {
    setFormValues({
      name: castingOppInfo.name ? castingOppInfo.name : '',
      requested_by: castingOppInfo.requested_by
        ? castingOppInfo.requested_by
        : '',
      type: castingOppInfo.type ? castingOppInfo.type : '',
      via: castingOppInfo.via ? castingOppInfo.via : '',
      sow: castingOppInfo.sow ? castingOppInfo.sow : '',
      description: castingOppInfo.description ? castingOppInfo.description : '',
      due_date: castingOppInfo.due_date ? castingOppInfo.due_date : '',
      due_time: castingOppInfo.due_time ? castingOppInfo.due_time : '',
    });
  }, [castingOppInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)',
          }}
        />
        <ModalContent mt="30px">
          <ModalHeader>
            {isEdited ? 'Edit Casting Opp' : 'Add Casting Opp'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex flexDirection="column">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Name"
                  name="name"
                  onChange={handleInputChange}
                  value={formValues.name}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Requested By</FormLabel>
                <Select
                  placeholder="Requested By"
                  onChange={handleInputChange}
                  value={formValues.requested_by}
                  name="requested_by"
                >
                  {Managers.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Type"
                  onChange={handleInputChange}
                  value={formValues.type}
                  name="type"
                >
                  {AllTypes.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Via</FormLabel>
                <Input
                  placeholder="Via"
                  onChange={handleInputChange}
                  value={formValues.via}
                  name="via"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>SOW</FormLabel>
                <Textarea
                  placeholder="SOW"
                  onChange={handleInputChange}
                  value={formValues.sow}
                  name="sow"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Description"
                  onChange={handleInputChange}
                  value={formValues.description}
                  name="description"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Due Date</FormLabel>
                <Input
                  placeholder="Due Date"
                  onChange={handleInputChange}
                  value={formValues.due_date}
                  name="due_date"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Due Time</FormLabel>
                <Input
                  placeholder="Due Time"
                  onChange={handleInputChange}
                  value={formValues.due_time}
                  name="due_time"
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="palette" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)',
          }}
        />
        <ModalContent>
          <ModalHeader>Submit Casting Opp</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Are you sure you submitted this casting opp to the partner? This
              cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button color="red.500" mr={3}>
              Yes
            </Button>
            <Button onClick={onDeleteClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isRestoreOpen}
        onClose={onRestoreClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)',
          }}
        />
        <ModalContent>
          <ModalHeader>Restore Casting Opp</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Are you sure you want to restore this casting opp?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button color="palette.500" mr={3}>
              Yes
            </Button>
            <Button onClick={onRestoreClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
