import {
  Flex,
  Text,
  useColorModeValue,
  Select,
  Icon,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';

import React from 'react';
import Card from 'components/card/Card.js';
import castingOppData from '../variables/castingopp-data.json';
import { MdEdit, MdDelete, MdRestore } from 'react-icons/md';
import { IoIosAddCircle } from 'react-icons/io';
import CastingOppModal from './CastingOppModal';

export default function CastingOppsCard(props) {
  const { curCastingOpp, setCurCastingOpp, castingOpps } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isRestoreOpen,
    onOpen: onRestoreOpen,
    onClose: onRestoreClose,
  } = useDisclosure();
  const [castingOppIsEdited, setCastingOppIsEdited] = React.useState(false);

  const [modalCastingOpp, setModalCastingOpp] = React.useState(
    castingOppData[0],
  );
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const handleCastingOppChange = (e) => {
    setCurCastingOpp(
      castingOppData.filter((item) => item.name === e.target.value)[0],
    );
  };

  const handleEditCastingOpp = () => {
    setModalCastingOpp(curCastingOpp);
    setCastingOppIsEdited(true);
    onOpen();
  };

  const handleCreateCastingOpp = () => {
    setModalCastingOpp({});
    setCastingOppIsEdited(false);
    onOpen();
  };

  return (
    <>
      <CastingOppModal
        isOpen={isOpen}
        onClose={onClose}
        isDeleteOpen={isDeleteOpen}
        onDeleteClose={onDeleteClose}
        isEdited={castingOppIsEdited}
        castingOppInfo={modalCastingOpp}
        isRestoreOpen={isRestoreOpen}
        onRestoreClose={onRestoreClose}
      />
      <Card align="center" direction="column" w="100%" backgroundImage={curCastingOpp.is_active ? "linear-gradient(0deg, green, white)" : "linear-gradient(0deg, red, white)"}>
        <Flex
          justifyContent="space-between"
          px="10px"
          pt="5px"
          alignItems="center"
        >
          <Text fontSize="22px" color={textColor} fontWeight="600">
            Casting Opps
          </Text>

          <Flex gap="10px">
            {curCastingOpp.is_active ? (
              <>
                <Tooltip label="Add">
                  <span>
                    <Icon
                      as={IoIosAddCircle}
                      color="palette.500"
                      w="22px"
                      h="22px"
                      cursor="pointer"
                      onClick={handleCreateCastingOpp}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Edit">
                  <span>
                    <Icon
                      as={MdEdit}
                      color="palette.500"
                      w="22px"
                      h="22px"
                      cursor="pointer"
                      onClick={handleEditCastingOpp}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Delete">
                  <span>
                    <Icon
                      as={MdDelete}
                      color="red"
                      w="22px"
                      h="22px"
                      cursor="pointer"
                      onClick={onDeleteOpen}
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              <Tooltip label="Restore" aria-label="A tooltip">
                <span>
                  <Icon
                    as={MdRestore}
                    color="palette.500"
                    w="22px"
                    h="22px"
                    cursor="pointer"
                    onClick={onRestoreOpen}
                  />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" mt={4}>
          <Select value={curCastingOpp.name} onChange={handleCastingOppChange} borderColor="black">
            {castingOpps.map((item) => {
              return <option value={item.name}>{item.name}</option>;
            })}
          </Select>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold">Requested By:</Text>
            <Text>{curCastingOpp.requested_by}</Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              Type:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.type}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              Via:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.via}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              SOW:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.sow}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              Description:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.description}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              Due Date:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.due_date}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Text fontWeight="bold" textAlign="left" w="30%">
              Due Time:
            </Text>
            <Text textAlign="right" w="70%">
              {curCastingOpp.due_time}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
