import {
  Modal,
  Button,
  Input,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/DatePicker.css';
import * as React from 'react';

export default function UIDsTableModal(props) {
  const { isOpen, onClose, isDeleteOpen, onDeleteClose, isEdited, dealInfo } =
    props;

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [selectedDate, setSelectedDate] = React.useState(dealInfo.date ? new Date(dealInfo.date) : new Date());

  const [formValues, setFormValues] = React.useState({});

  React.useEffect(() => {
    setFormValues({
      pay_date: dealInfo.pay_date ? dealInfo.pay_date : '',
      uid: dealInfo.uid ? dealInfo.uid : '',
      trello_card: dealInfo.trello_card ? dealInfo.trello_card : '',
      creator: dealInfo.creator ? dealInfo.creator : '',
      campaign: dealInfo.campaign ? dealInfo.campaign : '',
      category: dealInfo.category ? dealInfo.category : '',
      agency_partner: dealInfo.agency_partner ? dealInfo.agency_partner : '',
      total_invoiced_amount: dealInfo.total_invoiced_amount
        ? dealInfo.total_invoiced_amount
        : '',
      creator_pay: dealInfo.creator_pay ? dealInfo.creator_pay : '',
      manager: dealInfo.manager ? dealInfo.manager : '',
      invoice_no: dealInfo.invoice_no ? dealInfo.invoice_no : '',
      commission_paydate: dealInfo.commission_paydate
        ? dealInfo.commission_paydate
        : '',
      manager_commissions: dealInfo.manager_commissions
        ? dealInfo.manager_commissions
        : '',
    });
  }, [dealInfo])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)'
          }}
        />
        <ModalContent>
          <ModalHeader>{isEdited ? 'Edit Deal' : 'Add Deal'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <DatePicker
                selected={selectedDate}
                onChange={(e) => setSelectedDate(e)}
                style
                customInput={<Input name="date"/>}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>UID</FormLabel>
              <Input
                placeholder="UID"
                name="uid"
                onChange={handleInputChange}
                value={formValues.uid}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Trello Link</FormLabel>
              <Input
                placeholder="Trello Link"
                name="trello_card"
                onChange={handleInputChange}
                value={formValues.trello_card}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Creator</FormLabel>
              <Input
                placeholder="Creator"
                name="creator"
                onChange={handleInputChange}
                value={formValues.creator}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Campaign</FormLabel>
              <Input
                placeholder="Campaign"
                name="campaign"
                onChange={handleInputChange}
                value={formValues.campaign}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Category</FormLabel>
              <Input
                placeholder="Category"
                name="category"
                onChange={handleInputChange}
                value={formValues.category}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Agency / Partner</FormLabel>
              <Input
                placeholder="Agency / Partner"
                name="agency_partner"
                onChange={handleInputChange}
                value={formValues.agency_partner}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Total Invoiced Amount</FormLabel>
              <Input
                placeholder="Total Invoice Amount ($)"
                name="total_invoiced_amount"
                onChange={handleInputChange}
                value={formValues.total_invoiced_amount}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Creator Pay</FormLabel>
              <Input
                placeholder="Creator Pay ($)"
                name="creator_pay"
                onChange={handleInputChange}
                value={formValues.creator_pay}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Manager</FormLabel>
              <Input
                placeholder="Manager"
                name="manager"
                onChange={handleInputChange}
                value={formValues.manager}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="palette" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        size={{ sm: 'xs', md: 'md' }}
        isCentered
      >
        <ModalOverlay
          style={{
            backdropFilter: 'blur(10px)'
          }}
        />
        <ModalContent>
          <ModalHeader>Delete the Deal</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>Are you sure to delete the deal? This cannot be undone.</Text>
          </ModalBody>
          <ModalFooter>
            <Button color="red.500" mr={3}>
              Yes
            </Button>
            <Button onClick={onDeleteClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
