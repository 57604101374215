import React from 'react';
import {
  useColorModeValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react';
import leaderboardData from '../variables/uids-leaderboard.json';

import first from 'assets/img/leaderboard/1.png';
import second from 'assets/img/leaderboard/2.png';
import third from 'assets/img/leaderboard/3.png';

function RightSideBar(props) {
  const { isOpen, onClose } = props;

  let sidebarBackgroundColor = useColorModeValue('palette.500', 'navy.800');

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay />
      <DrawerContent w="305px" maxW="305px" bg={sidebarBackgroundColor}>
        <DrawerCloseButton
          zIndex="3"
          onClose={onClose}
          _focus={{ boxShadow: 'none' }}
          _hover={{ boxShadow: 'none' }}
        />
        <DrawerBody maxW="305px" px="0rem" pb="0">
          <Flex p="20px" mt="20px" align="center" flexDirection="column">
            <Text fontWeight="bold" fontSize="18px" color="white">
              Manager Leaderboard
            </Text>
            <Text fontWeight="bold" fontSize="16px" mb="10px" color="white">
              - Total Invoiced Amount -
            </Text>
            <Flex flexDirection="column" align="center" mb="10px">
              <Image src={first} w="90px" loading="lazy" />
              <Text color="white">{leaderboardData[0].name}</Text>
              <Text color="white" fontWeight="bold">
                {leaderboardData[0].invoiced_amount}
              </Text>
            </Flex>

            <Flex flexDirection="column" align="center" mb="10px">
              <Image src={second} w="90px" loading="lazy"/>
              <Text color="white">{leaderboardData[1].name}</Text>
              <Text color="white" fontWeight="bold">
                {leaderboardData[1].invoiced_amount}
              </Text>
            </Flex>

            <Flex flexDirection="column" align="center" mb="10px">
              <Image src={third} w="90px" loading="lazy"/>
              <Text color="white">{leaderboardData[2].name}</Text>
              <Text color="white" fontWeight="bold">
                {leaderboardData[2].invoiced_amount}
              </Text>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default RightSideBar;
