import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  Input,
  Button,
  Tbody,
  Thead,
  Td,
  Tr,
  Th,
  Table,
} from '@chakra-ui/react';

import React from 'react';
import Card from 'components/card/Card.js';
import castingOppData from 'views/dashboard/castingopp/variables/castingopp-creators.json';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

const columnHelper = createColumnHelper();

export default function CastingOppsPad(props) {
  const { curCastingOpp } = props;

  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const defaultData = castingOppData.filter(
    (item) => item.casting_opp_name === curCastingOpp.name,
  )[0].creators;

  const [data, setData] = React.useState(() => [...defaultData]);
  const [markingValues, setMarkingValues] = React.useState(
    data.reduce((acc, item) => {
      acc[item.creator] = item.marking;
      return acc;
    }, {})
  );

  const handleMarkingChange = (e, creator) => {
    setMarkingValues((prevValues) => ({
      ...prevValues,
      [creator]: e.target.value,
    }));
  }
  
  React.useEffect(() => {
    setMarkingValues(data.reduce((acc, item) => {
        acc[item.creator] = item.marking;
        return acc;
      }, {}));
  }, [data]);

  const columns = React.useMemo(() => [
    columnHelper.accessor('creator', {
      id: 'creator',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CREATOR
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700" w="120px">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('marking', {
      id: 'marking',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          MARKING
        </Text>
      ),
      cell: (info) => (
        <Input
          defaultValue={markingValues[info.row.original.creator] || ''}
          onBlur={(e) => handleMarkingChange(e, info.row.original.creator)}
          color={textColor}
          fontSize="sm"
          fontWeight="700"
          w="120px"
        />
      ),
    }),
  ], [markingValues, textColor]); // Add dependencies that might change


  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  React.useEffect(() => {
    setData(
      castingOppData.filter(
        (item) => item.casting_opp_name === curCastingOpp.name,
      )[0].creators,
    );
  }, [curCastingOpp]);

  return (
    <>
      <Card flexDirection="column" w="100%" align="center">
        <Flex
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
          alignItems={{ xl: 'center' }}
          justifyContent={{ xl: 'space-between' }}
          pt="5px"
        >
          <Text fontSize="22px" color={textColor} fontWeight="600">
            Casting Opps Marking
          </Text>

          <Button variant="palette" size={{ sm: 'sm', md: 'md' }}>
            Save
          </Button>
        </Flex>

        <Box>
          <Box>
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted()] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table
                  .getRowModel()
                  .rows.slice(0, 5)
                  .map((row) => {
                    return (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Td
                              key={cell.id}
                              fontSize={{ sm: '14px' }}
                              minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                              borderColor="transparent"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center" mt="5">
          <Button
            pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
            color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
            onClick={() => {
              setPagination({
                pageIndex: table.getState().pagination.pageIndex - 1,
                pageSize: 5,
              });
              table.setPageIndex(table.getState().pagination.pageIndex - 1);
            }}
          >
            Previous
          </Button>
          <Text p="1">
            Page{' '}
            {table.getPageOptions().length > 0
              ? table.getState().pagination.pageIndex + 1
              : 0}{' '}
            of {table.getPageOptions().length}
          </Text>
          <Button
            onClick={() => {
              setPagination({
                pageIndex: table.getState().pagination.pageIndex + 1,
                pageSize: 5,
              });
              table.setPageIndex(table.getState().pagination.pageIndex + 1);
            }}
            pointerEvents={
              pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
            }
            color={
              pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
            }
          >
            Next
          </Button>
        </Box>
      </Card>
    </>
  );
}
