/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import 'assets/css/ScrollBar.css';
import ExpandableCell from './ExpandableCell';

const columnHelper = createColumnHelper();

export default function AccountsInfoTable(props) {
  const { tableData, searchInput } = props;
  const [sorting, setSorting] = React.useState([]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [expandedCellId, setExpandedCellId] = React.useState(null);


  let defaultData = tableData;
  const columns = [
    columnHelper.accessor('tiktok_main', {
      id: 'tiktok_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TIKTOK MAIN
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('tiktok_2', {
      id: 'tiktok_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TikTok 2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('ig_main', {
      id: 'ig_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          IG Main
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('ig_2', {
      id: 'ig_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          IG 2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('yt_main', {
      id: 'yt_main',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          YT Main
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('yt_2', {
      id: 'yt_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          YT 2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('snapchat', {
      id: 'snapchat',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Snapchat
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('twitter', {
      id: 'twitter',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Twitter
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('facebook', {
      id: 'facebook',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Facebook
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="100px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('tiktok_main_audio_promo', {
      id: 'tiktok_main_audio_promo',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TikTok Main Audio Promo
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('tiktok_2_audio_promo', {
      id: 'tiktok_2_audio_promo',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TikTok 2 Audio Promo
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('audio_genre_1', {
      id: 'audio_genre_1',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Audio Genre 1
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('audio_genre_2', {
      id: 'audio_genre_2',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Audio Genre 2
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('audio_genre_3', {
      id: 'audio_genre_3',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Audio Genre 3
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('metropolis', {
      id: 'metropolis',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Metropolis
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="80px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('agenda', {
      id: 'agenda',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Agenda
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          hasLink
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('analytics_folder', {
      id: 'analytics_folder',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Analytics Folder
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          hasLink
          width="50px"
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
    columnHelper.accessor('google_folder', {
      id: 'google_folder',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Google Folder
        </Text>
      ),
      cell: (info) => (
        <ExpandableCell
          value={info.getValue()}
          width="50px"
          hasLink
          textColor={textColor}
          cellId={info.cell.id}
          expandedCellId={expandedCellId}
          setExpandedCellId={setExpandedCellId}
        />
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  
  React.useEffect(() => {
    if (searchInput.length > 0) {
      const filteredData = defaultData.filter((item) =>
        item['tiktok_main'].includes(searchInput),
      );
      setData(filteredData);
    } else {
      setData([...defaultData]);
    }
  }, [searchInput])

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        justifyContent={{ xl: 'space-between' }}
        gap={{ base: '20px', xl: '20px' }}
        padding={{ base: '20px', xl: '20px' }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Accounts Info
        </Text>
      </Flex>
      <Box className="scroll-wrapper">
        <Box className="custom-scrollbar">
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" mt="5">
        <Button
          pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
          color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex - 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex - 1);
          }}
        >
          Previous
        </Button>
        <Text p="1">
          Page{' '}
          {table.getPageOptions().length > 0
            ? table.getState().pagination.pageIndex + 1
            : 0}{' '}
          of {table.getPageOptions().length}
        </Text>
        <Button
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex + 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex + 1);
          }}
          pointerEvents={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
          }
          color={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
          }
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
