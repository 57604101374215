// Chakra imports
import { Flex, Text, useColorModeValue, Box } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import BarChart from 'components/charts/BarChart';
import CreatorStatus from '../variables/dropdowns/accountshq-status.json';
import BarChartOptions from 'variables/BarChartOptions';
import React from 'react';

export default function StatusPieChart() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const chartOptions = BarChartOptions(CreatorStatus, 'number');
  const chartData = [
    {
      name: 'Creator Count',
      data: CreatorStatus.map((item) => item.value),
    },
  ];

  return (
    <Card p="20px" align="center" direction="column" w="100%" h="250px">
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Creator Status
        </Text>
      </Flex>

      <Box h="180px" mt="auto">
        <BarChart chartData={chartData} chartOptions={chartOptions} />
      </Box>
    </Card>
  );
}
