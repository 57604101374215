import {
    Flex,
    Text,
    useColorModeValue,
    Select
  } from '@chakra-ui/react';
  
  import React from 'react';
  import Card from 'components/card/Card.js';
  import castingOppData from 'views/dashboard/castingopp/variables/castingopp-data.json';
  
  export default function CastingOppsCard(props) {
    const { curCastingOpp, setCurCastingOpp, castingOpps } = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
  
    const handleCastingOppChange = (e) => {
      setCurCastingOpp(
        castingOppData.filter((item) => item.name === e.target.value)[0],
      );
    };

    return (
      <>
        <Card align="center" direction="column" w="100%">
          <Flex
            justifyContent="space-between"
            px="10px"
            pt="5px"
            alignItems="center"
          >
            <Text fontSize="22px" color={textColor} fontWeight="600">
              Casting Opps
            </Text>

          </Flex>
          <Flex flexDirection="column" mt={4}>
            <Select value={curCastingOpp.name} onChange={handleCastingOppChange} borderColor="black">
              {castingOpps.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </Select>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold">Requested By:</Text>
              <Text>{curCastingOpp.requested_by}</Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                Type:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.type}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                Via:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.via}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                SOW:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.sow}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                Description:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.description}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                Due Date:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.due_date}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" textAlign="left" w="30%">
                Due Time:
              </Text>
              <Text textAlign="right" w="70%">
                {curCastingOpp.due_time}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </>
    );
  }
  