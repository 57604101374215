/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  Select,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import 'assets/css/ScrollBar.css';
import ExpandableCell from 'views/dashboard/accountshq/components/ExpandableCell';
import AccountsHQData from 'views/dashboard/accountshq/variables/AccountsHQData.json';
import DemographicsDropdowns from '../variables/dropdowns/demographics.json';
import AnalyticsDropdowns from '../variables/dropdowns/analytics.json';

const columnHelper = createColumnHelper();

export default function AnalyticsTable(props) {
  const { searchInput, platform } = props;
  const [sorting, setSorting] = React.useState([]);
  const [firstDropdownValue, setFirstDropdownValue] = React.useState(
    AnalyticsDropdowns.filter((item) =>
      item.platform.includes(platform.name),
    )[0].data[0],
  );
  const [secondDropdownValue, setSecondDropdownValue] = React.useState(
    AnalyticsDropdowns.filter((item) =>
      item.platform.includes(platform.name),
    )[0].data[1],
  );

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [expandedCellId, setExpandedCellId] = React.useState(null);

  const updateData = () => {
    return AccountsHQData.map((item) =>
      item.stats
        .filter((stat) => stat.platform === platform.name)[0]
        .data.reduce((acc, item) => {
          acc[item.name.replace('.', '')] = item.value;
          return acc;
        }, {}),
    );
  };

  const [analyticsData, setAnalyticsData] = React.useState(() => updateData());

  React.useEffect(() => {
    setAnalyticsData(updateData);
  }, [platform]);

  React.useEffect(() => {
    setAnalyticsData(
      AccountsHQData.filter((item) =>
        item.tiktok_main.includes(searchInput),
      ).map((item) =>
        item.stats
          .filter((stat) => stat.platform === platform.name)[0]
          .data.reduce((acc, item) => {
            acc[item.name.replace('.', '')] = item.value;
            return acc;
          }, {}),
      ),
    );
  }, [searchInput]);

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          textTransform="capitalize"
          paddingBottom="10px"
          paddingTop="10px"
        >
          Analytics
        </Text>
      ),
      columns: [
        columnHelper.accessor(firstDropdownValue.replace('.', ''), {
          id: firstDropdownValue.replace('.', ''),
          header: () => (
            <Select
              fontSize="12px"
              value={firstDropdownValue}
              onChange={(e) => setFirstDropdownValue(e.target.value)}
            >
              {AnalyticsDropdowns.filter((item) =>
                item.platform.includes(platform.name),
              )[0]
                .data.filter((item) => item != secondDropdownValue)
                .map((item) => {
                  return <option value={item}>{item}</option>;
                })}
            </Select>
          ),
          cell: (info) => (
            <ExpandableCell
              value={info.getValue()}
              width="100px"
              textColor={textColor}
              cellId={info.cell.id}
              expandedCellId={expandedCellId}
              setExpandedCellId={setExpandedCellId}
            />
          ),
          enableSorting: false,
        }),
        columnHelper.accessor(secondDropdownValue.replace('.', ''), {
          id: secondDropdownValue.replace('.', ''),
          header: () => (
            <Select
              fontSize="12px"
              value={secondDropdownValue}
              onChange={(e) => setSecondDropdownValue(e.target.value)}
            >
              {AnalyticsDropdowns.filter((item) =>
                item.platform.includes(platform.name),
              )[0]
                .data.filter((item) => item != firstDropdownValue)
                .map((item) => {
                  return <option value={item}>{item}</option>;
                })}
            </Select>
          ),
          cell: (info) => (
            <ExpandableCell
              value={info.getValue()}
              width="100px"
              textColor={textColor}
              cellId={info.cell.id}
              expandedCellId={expandedCellId}
              setExpandedCellId={setExpandedCellId}
            />
          ),
          enableSorting: false,
        }),
      ],
      enableSorting: false,
    }),
  ];
  const table = useReactTable({
    data: analyticsData,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Box>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id} height="50px">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" mt="5">
        <Button
          pointerEvents={pagination.pageIndex === 0 ? 'none' : 'auto'}
          color={pagination.pageIndex === 0 ? 'gray' : 'auto'}
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex - 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex - 1);
          }}
        >
          Previous
        </Button>
        <Text p="1">
          Page{' '}
          {table.getPageOptions().length > 0
            ? table.getState().pagination.pageIndex + 1
            : 0}{' '}
          of {table.getPageOptions().length}
        </Text>
        <Button
          onClick={() => {
            setPagination({
              pageIndex: table.getState().pagination.pageIndex + 1,
              pageSize: 5,
            });
            table.setPageIndex(table.getState().pagination.pageIndex + 1);
          }}
          pointerEvents={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'none' : 'auto'
          }
          color={
            pagination.pageIndex + 1 >= table.getPageCount() ? 'gray' : 'auto'
          }
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
