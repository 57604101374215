import CastingOppsCard from './components/CastingOppsCard';
import CastingOppsPad from './components/CastingOppsPad';
import ScratchPad from './components/ScratchPad';
import WorkbookTable from './components/WorkBookTable';
import DemographicsTable from './components/DemographicsTable';
import AnalyticsTable from './components/AnalyticsTable';
import { Box, SimpleGrid } from '@chakra-ui/react';
import castingOppData from 'views/dashboard/castingopp/variables/castingopp-data.json';
import React from 'react';
import Platforms from 'views/dashboard/workbook/variables/dropdowns/platforms.json';

export default function WorkbookDashboard() {
  const [searchInput, setSearchInput] = React.useState('');
  const [platform, setPlatform] = React.useState(Platforms[0]);


  const activeCastingOpps = castingOppData.filter((item) => item.is_active);
  const [curCastingOpp, setCurCastingOpp] = React.useState(
    activeCastingOpps[0],
  );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px">
        <CastingOppsCard
          castingOpps={activeCastingOpps}
          curCastingOpp={curCastingOpp}
          setCurCastingOpp={setCurCastingOpp}
        />
        <CastingOppsPad curCastingOpp={curCastingOpp} />
        <ScratchPad curCastingOpp={curCastingOpp} />
      </SimpleGrid>

      <SimpleGrid gap="20px" mb="20px">
        <WorkbookTable
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <DemographicsTable searchInput={searchInput} platform={platform} setPlatform={setPlatform}/>
          <AnalyticsTable searchInput={searchInput} platform={platform}/>
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
