// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import LineChart from 'components/charts/LineChart';
import React from 'react';
import { RiArrowUpSFill } from 'react-icons/ri';
import LineChartOptions from 'variables/LineChartOptions';
import IconBox from 'components/icons/IconBox';
export default function ViewsLineChart(props) {
  const { data, icon } = props;

  const chartOptions = LineChartOptions(data);

  const chartData = [
    {
      name: 'Avg. Views',
      data: data[0].data.map((item) => item.views),
    },
    {
      name: 'Avg. Dec. Views',
      data: data[0].data.map((item) => item.decile_views),
    },
  ];

  const brandColor = useColorModeValue('palette.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
    >
      <Flex
        my="auto"
        h="100%"
        align={{ base: 'center', xl: 'start' }}
        justify={{ base: 'center', xl: 'center' }}
        mb="20px"
      >
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={<Icon w="32px" h="32px" as={icon} color={brandColor} />}
        />

        <Stat my="auto" ms="18px">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: 'sm',
            }}
          >
            Total Followers
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: '2xl',
            }}
          >
            {data[0].followers}
          </StatNumber>
        </Stat>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex mb="20px" align="center">
          <Text
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="500"
            mt="4px"
            me="12px"
          >
            Avg. Views
          </Text>
          <Flex align="center">
            <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
            <Text color="green.500" fontSize="sm" fontWeight="700">
              {data[0].change.views}
            </Text>
          </Flex>
        </Flex>
        <Flex align="center" mb="20px">
          <Text
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="500"
            mt="4px"
            me="12px"
          >
            Avg. Dec. Views
          </Text>
          <Flex align="center">
            <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
            <Text color="green.500" fontSize="sm" fontWeight="700">
              {data[0].change.decile_views}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box minH="260px" minW="75%" mt="auto">
        <LineChart chartData={chartData} chartOptions={chartOptions} />
      </Box>
    </Card>
  );
}
